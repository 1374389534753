import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {themeColor} from "../../theme/custom-colors";

const useStyles = makeStyles((theme) => ({
    text_color: {
        [theme.breakpoints.down('sm')]: {
            color: themeColor
        },
        color: 'white'
    }
}));

export function Copyright() {
    const classes = useStyles();

    return (
        <Typography variant="body2" className={classes.text_color} align="center">
            <Link color="inherit" href="https://iotec.io">
                Powered by ioTec&nbsp;
            </Link>
            {`© ${new Date().getFullYear()}.`}
        </Typography>
    );
}
