import purple from '@material-ui/core/colors/cyan';
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import {colors} from "@material-ui/core";
import {blue, grey} from "@material-ui/core/colors";


export const themeColor = '#076635';
export const themeColorLight = "#076635"
export const themeColorSecondary = "#428bca"
export const backgroundGrey = grey[100]
export const themeBackground = purple[700]
export const successColor = green[600]
export const blueColor = blue[600]
export const warningColor = amber[800]
export const errorColor = red[500]
export const defaultColor = grey[500]
export const iconColor = colors.blueGrey[600]
export const linkColor = "#428bca"
export const white = '#FFFFFF';
export const black = '#000000';


export const warning = {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
}
