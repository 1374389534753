import React from 'react';
import {Box, Card, Chip, Grid, Typography, useTheme} from "@material-ui/core";
import numeral from "numeral";
import Loading from "../../../components/loaders/Loading";
import ErrorDisplay from "../../../components/ErrorDisplay";
import {LoanAgingSummaryModel} from "../../../__generated__/apiTypes";
import grey from "@material-ui/core/colors/grey";
import {themeColor} from "../../../theme/custom-colors";

interface IProps {
    onFilter: (filter: any) => void
    filter: any
    data: LoanAgingSummaryModel
    loading: boolean
    error: any
}


type ReportCardProps = {
    text: string
    value: number
    count: number
    isChecked: boolean
    onCheck: () => void
}
const ReportCard: React.FC<ReportCardProps> = ({count, text, value, isChecked, onCheck}) => {
    const theme = useTheme()
    return <Grid item sm={3} style={{borderRight: `1px solid ${theme.palette.divider}`}}>
        <Box p={2} style={{backgroundColor: isChecked ? grey[100] : undefined, cursor: 'grab'}} onClick={onCheck}>
            <Typography
                color="textSecondary"
                variant="overline"
            >
                <b>{text}&nbsp;&nbsp;<Chip label={`${numeral(count).format('0,0')}`} size="small" style={{backgroundColor:themeColor}} /></b>
            </Typography>
            <Typography
                color="textPrimary"
                variant="h5"
            >
                <span style={{fontSize: 'large'}}>UGX&nbsp;</span>
                {numeral(value).format('0,0.0')}
            </Typography>
        </Box>
    </Grid>
}

const ReportSummary: React.FC<IProps> = ({filter, onFilter, loading, data, error}: IProps) => {

    const handleFilter = (category?: string) => () => {

        if (category) {
            onFilter({category})
        } else
            onFilter({})
    }

    if (loading) return <Loading/>;
    if (!data) return <ErrorDisplay text={error?.message ?? 'Failed to load data, check connectivity.'}/>;
    return (
        <Card>
            <Grid container spacing={0}>
                <ReportCard
                    text="Total Loans"
                    value={data.totalLoans!}
                    count={data.totalLoansCount!}
                    isChecked={false}
                    onCheck={handleFilter(undefined)}
                />
                <ReportCard
                    text="Due in 7 days"
                    value={data.dueIn7Days!}
                    count={data.dueIn7DaysCount!}
                    isChecked={filter['category'] === 'B'}
                    onCheck={handleFilter('B')}
                />
                <ReportCard
                    text="Due in 1-30 days"
                    value={data.dueIn30Days!}
                    count={data.dueIn30DaysCount!}
                    isChecked={filter['category'] === 'A'}
                    onCheck={handleFilter('A')}
                />
                <ReportCard
                    text="31 days and above"
                    value={data.overdue!}
                    count={data.overdueCount!}
                    isChecked={filter['category'] === 'C'}
                    onCheck={handleFilter('C')}
                />
            </Grid>
        </Card>
    );
}


export default ReportSummary;
