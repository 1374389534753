import logo  from "../assets/logo-clean.png"
export const AUTH_KEY_TOKEN = '__ri_client__token'
export const AUTH_KEY_USER = '__ri_client__user'
export const APP_NAME = 'Penda Capital'
export const appLogo = logo
export const companyRefUrl = "https://www.pendacapital.org"
export const APP_NAME_SHORT = 'Penda Capital'
export const SupportEmail = 'info@pendacapital.org'
export const SupportPhone = '+256 757 833-500'
export const ioTecName = 'ioTec Limited'
export const termsLink = './Terms.pdf'
export const passwordRegex = /^[a-zA-Z\d]{6,}$/

export const MAX_IMPORT_DATA = 10000

// Anything above this should be a special request
export const MAX_EXPORT_DATA_COUNT = 100000;

export const redux = {
    doLogin: 'DO_LOGIN',
    doLogout: 'DO_LOGOUT',
    doSearch: 'DO_SEARCH',
};

export const localRoutes = {
    dashboard: '/dashboard',
    dashboardHidden: '/hidden-dash',
    contacts: '/contacts',
    contactsDetails: '/contacts/:contactId',

    loans: '/loans',
    loansAging: '/aging',
    pay: '/pay',
    loansDetails: '/loans/:loanId',

    invoices: '/invoices',
    invoicesRecover: '/invoices/recover',
    invoicesRecoverView: '/invoices/recover/:invoiceId',
    invoicesDetails: '/invoices/:invoiceId',


    users: '/admin/users',
    usersGroups: '/admin/user-groups',
    tags: '/admin/tags',
    settings: '/admin/settings',
    help: '/help',
    login: '/login',
    employeeRegister: '/register',

    forgotPass: '/forgot',
    setPassword: '/auth/set-password',

    activate: '/auth/activate',
    resetPassword: '/auth/reset-password',

    singleSmsList: '/sms/single',
    msgTemplates: '/mgs/templates',
    msgTemplatesCreate: '/mgs/templates/new',
    msgTemplatesDetails: '/mgs/templates/:templateId',
    singleSmsCreate: '/sms/single/create',
    singleSmsDetails: '/sms/single/:messageId',

    bulkSmsList: '/sms/bulk',
    bulkSmsCreate: '/sms/bulk/create',
    bulkSmsDetails: '/sms/bulk/:messageId'
}

const urls: any = {
    dev: 'http://localhost:9001',
    test: 'https://iotec-loans.azurewebsites.net',
    production: 'https://penda.iotec.io'
}


const evVar = process.env.REACT_APP_ENV || 'production'
const environment = evVar.trim()
console.log(`############# Env : ${environment} ###############`)
const url = urls[environment]
export const isDebug = environment === 'dev';

export const remoteRoutes = {
    authServer: url,
    login: url + '/api/auth/login',
    forgotPassword: url + '/api/auth/forgot',
    setNewPassword: url + '/api/auth/set-password',
    activate: url + '/api/auth/activate',
    resetPassword: url + '/api/auth/reset-password',
    loginPhone: url + '/api/auth/phone-login',
    profile: url + '/api/auth/profile',
    register: url + '/api/auth/register',
    resetPass: url + '/reset',
    contacts: url + '/api/crm/contact',
    contactsAdminActivate: url + '/api/crm/admin/activate',
    contactsAdminOrg: url + '/api/crm/admin/org',
    contactsAdminResponsible: url + '/api/crm/admin/responsible',
    contactSearch: url + '/api/crm/contact/search',
    contactById: url + '/api/crm/contact/id',
    contactsPerson: url + '/api/crm/person',
    contactsChc: url + '/api/crm/person/chc',
    contactsEmail: url + '/api/crm/email',
    contactsTag: url + '/api/crm/tag',
    contactsUrl: url + '/api/crm/url',
    contactsNextOfKin: url + '/api/crm/next-of-kin',
    contactsBankAccount: url + '/api/crm/bankaccount',
    tags: url + '/api/tags',
    users: url + '/api/users',

    usersComboAll: url + '/api/user-combo/all',
    usersComboPrimary: url + '/api/user-combo/primary',


    accountManagersCombo: url + '/api/crm/account-manager/all',
    accountManagersCompanies: url + '/api/crm/account-manager/companies',
    accountManagersAdd: url + '/api/crm/account-manager/add-company',
    accountManagersRemove: url + '/api/crm/account-manager/remove-company',

    contactsPhone: url + '/api/crm/phone',
    contactsAddress: url + '/api/crm/address',
    contactsIdentification: url + '/api/crm/identification',

    groups: url + '/api/groups/group',
    peopleUpload: url + '/api/crm/import/people',
    peopleSample: url + '/api/crm/import/sample',




    contactsCompany: url + '/api/crm/company',
    contactTags: url + '/api/crm/tag',
    contactsCompanyCombo: url + '/api/crm/company',
    contactsAvatar: url + '/api/crm/contact/avatar',

    invoices: url + '/api/inv/invoice',
    invoicesGenerate: url + '/api/inv/process/generate',
    invoicesPay: url + '/api/inv/process/pay',
    invoicesUpdateFile: url + '/api/inv/process/update-receipt',
    invoicesUpdateCleared: url + '/api/inv/process/update-cleared',
    invoicesApprove: url + '/api/inv/process/approve',
    invoicesApprove1: url + '/api/inv/process/approve1',
    invoicesView: url + '/api/inv/process/view',
    invoicesEmail: url + '/api/inv/process/email',

    loans: url + '/api/loans/loan',
    loansTriggerPayment: url + '/api/loans/process/payout',
    loansTriggerRecover: url + '/api/loans/process/recover',
    loansRequestLoan: url + '/api/loans/originate/ussd',
    loansOriginate: url + '/api/loans/originate',
    loansRecovery: url + '/api/loans/recovery',
    loansAgingReport: url + '/api/loans/aging/report',
    loansAgingReportSummary: url + '/api/loans/aging/summary',
    loansStats: url + '/api/loans/stats',

    loansReportsByPeriod: url + '/api/loans/reports/loans-by-period',
    loansReportsByWeekDay: url + '/api/loans/reports/loans-by-weekday',



    sms: url + '/api/msg/sms',

    templates: url + '/api/msg/templates',
    templateTags: url + '/api/msg/template-tags',

    msgBulkRecipient: url + '/api/msg/bulk-recipients',
    bulkSmsRecipientsSample: url + '/api/msg/bulk-recipients/sms-sample',
    bulkSmsRecipientsUpload: url + '/api/msg/bulk-recipients/sms-upload',
    bulkSmsRecipientsRead: url + '/api/msg/bulk-recipients/read-file',
    msgBulkSms: url + '/api/msg/bulk-sms',

    documents: url + '/api/docs',
    documentsView: url + '/api/docs/view',
    documentsDownload: url + '/api/docs/download'
}
